// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import styles from './Sidebar.module.scss';
import { useCategoriesList } from '../../hooks';

const Sidebar = () => {
  const categories = useCategoriesList();
  const SERVERLESS_FN_URL = "https://serverless-api.bryanhuang.workers.dev/submit"

  return (
    <div className={styles['sidebar']}>
      <div className={styles['sidebar__desc']}>
        {'Photos by me unless otherwise stated.'}
      </div>
      <ul className={styles['sidebar__list']}>
        <li className={styles['sidebar__list']}>
          <Link className={styles['sidebar__list-link']} to='/blog'>
            <div className={styles['sidebar__list-link-item']}>Latest</div>
          </Link>
        </li>
        {categories.map((category) => (
          <li className={styles['sidebar__list']} key={category.fieldValue}>
            <Link className={styles['sidebar__list-link']} to={`/category/${kebabCase(category.fieldValue)}/`}>
              <div className={styles['sidebar__list-link-item']}>{category.fieldValue}</div>
            </Link>
          </li>
        ))}
      </ul>
      <form 
        action={SERVERLESS_FN_URL}
        method="POST"
        className={styles['sidebar__subform']}>
        <p className={styles['sidebar__subform-header']}>Stay in the Loop</p>
        <p className={styles['sidebar__subform-desc']}>I'm not the most public person, but every few months, I'll send out life updates, projects, and new guides.</p>
        <input 
          className={styles['sidebar__subform-email']}
          type="email"
          autoCapitalize="off"
          autoCorrect="off"
          name="email"
          id="email"
          size="25"
          required
          placeholder="Type your email.."
          aria-label="Email Address"/>
        <button
          className={styles['sidebar__subform-submit']} 
          type="submit">
          SUBSCRIBE
        </button>
      </form>
    </div>
  );
};

export default Sidebar;